<head>
    <link rel="canonical" href="https://plusmax.com.my/#/retail-sales" />
</head>
<div class="page-banner bg-6">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100 fon">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <h3>RETAIL SALES</h3>
                    <div class="service-image">
                        <img src="assets/img/Duty-free/duty.jpeg" alt="Plus Max Duty-Free">
                    </div>
                    <h3> PLUS MAX - DUTY FREE</h3><br>
                    <p class="fon"><i class='bx bx-drink'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>
                            Plus Max has is one of Malaysia’s largest regional duty-free trading organization, with its
                            strategic presence in nine
                            key entry and exit points in Peninsular Malaysia.
                        </strong></p>
                    <p class="fon"><i class='bx bx-drink'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>We
                            supply to various Embassies & Consulates and Airlines.</strong></p>
                    <div class="image">
                        <img src="assets/img/gallery/Madurai2.jpg" alt="Plus Max Duty-Free">
                    </div>
                    <p class="fon"><i class='bx bx-drink'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>These
                            have been strategically located in:<br>&nbsp;&nbsp;
                            <i class="bx bxs-ship" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Seaports in Port Klang Cruise Terminal in Klang Port and Kuantan Port<br>&nbsp;&nbsp;
                            <i class="bx bxs-plane-alt" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Downtown duty-free outlets in Kuala Lumpur and Selangor<br>&nbsp;&nbsp;
                            <i class="bx bxs-train" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp; Passenger
                            train terminals in Padang Besar and Pasir Gudang Passenger Terminal<br>&nbsp;&nbsp;
                            <i class="bx bxs-shopping-bag"
                                style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;&nbsp;Duty-free
                            store in Langkawi and
                            Duty-free store in Forest City, Johor
                        </strong>
                    </p>
                    <div class="image">
                        <img src="assets/img/Duty-free/PortKlang.jpg" alt="Plus Max Duty-Free">
                    </div>

                    <p class="fon"><i class='bx bx-drink'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>
                            The location encompasses the entire Malaysian spectrum, making the process seamless for our
                            customers. This enables us
                            to serve a diverse range of customers, including embassies and consulates.</strong></p>
                    <div class="image">
                        <img src="assets/img/gallery/kovai1.jpg" alt="Plus Max Duty-Free">
                    </div>
                    <p class="fon"><i class='bx bx-drink'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>
                            Our group has expanded internationally to the following regions:<br>
                            Five international airports in India:<br>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;<i
                                class="bx bxs-plane-take-off" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Madurai,Tamil Nadu<br>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                            &nbsp;
                            <i class="bx bxs-plane-take-off" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Coimbatore, Tamil Nadu<br>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                            <i class="bx bxs-plane-take-off" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Vishakapatnam, Andhra Pradesh<br>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                            <i class="bx bxs-plane-take-off" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Pune, Maharashtra<br>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                            <i class="bx bxs-plane-take-off" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Trivandrum, Kerala, respectively,<br>
                            <i class="bx bxs-plane-alt" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Kigali, Rwanda<br>
                            <i class="bx bx-buildings" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Dubai, United Arab Emirates<br>
                            <i class="bx bx-package" style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;
                            Zhuhai-Macao, Hong Kong</strong></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Retail Sales</h3>
                    <ul>
                        <li><a><i class='bx bx-money'></i>Sales</a></li>
                        <li><a><i class='bx bx-transfer'></i> B2B Exchange</a></li>
                        <li><a><i class='bx bx-shopping-bag'></i> Duty free</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
