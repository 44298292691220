<head>
    <link rel="canonical" href="https://plusmax.com.my/#/manufacturing" />
</head>
<div class="page-banner bg-13">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Manufacturing</h2>
                    <ul>
                        <li><a routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12"
                style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: medium;">
                <div class="service-details-text">
                    <div class="fon">
                        <div class="service-image">
                            <img src="assets/img/Manufacturing/Picture 3.png" alt="Plus Max Manufacturing">
                        </div>
                        <h3>Manufacturing</h3>
                        <p><i class='bx bxs-beer' style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>
                                BJ Distillery Sdn Bhd (BJD), Malaysia, planning to setup an integrated bioethanol
                                manufacturing plant and to become one
                                of the leading producers of bio-ethanol-derived products in the country.</strong>
                        </p>
                        <div class="image">
                            <img src="assets/img/Manufacturing/Picture 4.png" alt="Plus Max Manufacturing">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Manufacturing</h3>
                    <ul>
                        <li><a><i class='bx bx-wrench'></i>Manufacturing</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
