<head>
    <link rel="canonical" href="https://plusmax.com.my/#/gallery" />
</head>
<!-- Page banner Area -->
<div class="page-banner bg-14">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Gallery</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Gallery Area -->
<div class="gallery-area pt-100 pb-70 bg">
    <div class="container">
        <mat-tab-group>
            <mat-tab label="PLUS MAX DUTY FREE">
                <div class="row">
                    <div class="gallery-title">
                        <h2><strong>PLUS MAX DUTY FREE</strong></h2>
                    </div>
                    <h2>Forest City&nbsp;(Malaysia)</h2>
                    <div class="col-lg-4 col-md-6 dutyfree">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Forest City4.jpg" alt="PLUSMAX Duty free Forest City">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Forest City4.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Forest City2.jpg" alt="PLUSMAX Duty free Forest City">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Forest City2.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Madurai3.jpg" alt="PLUSMAX Duty free Forest City">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Madurai3.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Petrapole Land Port&nbsp;(India)</h2>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/DutyfreeWestbengal1.jpeg" alt="PLUSMAX Duty free Petrapole">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/DutyfreeWestbengal1.jpeg"><i
                                                class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/DutyfreeWestbengal2.jpeg" alt="PLUSMAX Duty free Petrapole">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/DutyfreeWestbengal2.jpeg"><i
                                                class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/DutyfreeWestbengal3.jpeg" alt="image">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/DutyfreeWestbengal3.jpeg"><i
                                                class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Coimbatore&nbsp;(India)</h2>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Arrival1.jpg" alt="PLUSMAX Duty free Coimbatore">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Arrival1.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Arrival3.jpg" alt="PLUSMAX Duty free Coimbatore">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Arrival3.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Departure3.jpg" alt="PLUSMAX Duty free Coimbatore">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Departure3.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Madurai&nbsp;(India)</h2>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Madurai2.jpg" alt="PLUSMAX Duty free Madurai">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Madurai2.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Madurai1.jpg" alt="PLUSMAX Duty free Madurai">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Madurai1.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Forest City3.jpg" alt="PLUSMAX Duty free Madurai">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Forest City3.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Pune&nbsp;(India)</h2>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Pune1.jpg" alt="PLUSMAX Duty free Pune">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Pune1.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Pune2.jpg" alt="PLUSMAX Duty free Pune">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Pune2.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Pune3.jpg" alt="PLUSMAX Duty free Pune">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Pune3.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Hong Kong</h2>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/HK1.jpg" alt="PLUSMAX Duty free Hong kong">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/HK1.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/HK2.jpg" alt="PLUSMAX Duty free Hong Kong">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/HK2.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/HK3.jpg" alt="PLUSMAX Duty free hong kong">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/HK3.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Rwanda</h2>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Rwanda1.jpeg" alt="PLUSMAX Duty free Rwanda">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Rwanda1.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Rwanda2.jpeg" alt="PLUSMAX Duty free Rwanda">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Rwanda2.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Rwanda3.jpeg" alt="PLUSMAX Duty free Rwanda">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Rwanda3.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="INFRASTRUCTURE">
                <div class="row">
                    <div class="gallery-title">
                        <h2><strong>PLUS MAX INFRASTRUCTURE</strong></h2>
                    </div>
                    <h2>SALEM SITE</h2>
                    <div class="col-lg-4 col-md-6 dutyfree">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/SalemS1.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/SalemS1.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/SalemS2.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/SalemS2.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/SalemS3.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/SalemS3.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>THANJAVUR SITE</h2>
                    <div class="col-lg-4 col-md-6 dutyfree">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/ThanjavurS1.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/ThanjavurS1.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/ThanjavurS2.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/ThanjavurS2.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/ThanjavurS3.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/ThanjavurS3.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>POOLAMBADI ROAD PROJECT</h2>
                    <div class="col-lg-4 col-md-6 dutyfree">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/Poolambadi1.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/Poolambadi1.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/Poolambadi4.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/Poolambadi4.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/Infra/Poolambadi5.jpeg" alt="PLUSMAX Infrastructure">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/Infra/Poolambadi5.jpeg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="PRIME CITY">
                <div class="row">
                    <div class="gallery-title">
                        <h2><strong>PRIME CITY</strong></h2>
                    </div>
                    <h2>PRIME CITY</h2>
                    <div class="col-lg-4 col-md-6 dutyfree">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Prime.jpg" alt="PLUSMAX Infrastructure Primecity">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Prime.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Prime1.jpg" alt="Prime City">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Prime1.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="floza-gallery">
                            <img src="assets/img/gallery/Prime2.jpg" alt="Prime City">
                            <div class="caption">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <a href="assets/img/gallery/Prime2.jpg"><i class='bx bx-show-alt'></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<!-- End Gallery Area -->
