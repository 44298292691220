<head>
    <!-- Google tag (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-L7NXHR86MY"></script>
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'G-L7NXHR86MY');
    </script>
    <link rel="canonical" href="https://plusmax.com.my/#/about" />
</head>
<div class="page-banner about-bg-1 mt-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>About Us</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-info-area ptb-100 fon">
    <div class="container font">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div data-aos="zoom-out" class="about-info-card">
                    <h3>Our Vision</h3>
                    <ul>
                        <li><i class='bx bx-check'></i><strong style="color: darkblue;">To have a presence in key
                                geographical regions and attain
                                leadership in all sectors we diversify into.</strong></li>
                    </ul>
                </div>
            </div>
            <div data-aos="zoom-out" class="col-lg-6 col-md-6">
                <div data-aos="zoom-out" class="about-info-card">
                    <h3>Our Mission</h3>
                    <ul>
                        <li class="typing"><i class='bx bx-check'></i><strong style="color: darkblue;">To offer a
                                wide-range of impeccable services by fostering a creative and innovative
                                culture.</strong></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="snowflakes" aria-hidden="true">
    <div class="snowflake">
        ❅
    </div>
    <div class="snowflake">
        ❅
    </div>
    <div class="snowflake">
        ❆
    </div>
    <div class="snowflake">
        ❄
    </div>
    <div class="snowflake">
        ❅
    </div>
    <div class="snowflake">
        ❆
    </div>
    <div class="snowflake">
        ❄
    </div>
    <div class="snowflake">
        ❅
    </div>
    <div class="snowflake">
        ❆
    </div>
    <div class="snowflake">
        ❄
    </div>
</div>
<div class="digital-area ptb-100 bg-ribbon fon">
    <div class="container font">
        <h1 style="font-weight: bold;" class="text-white">About Us</h1>
        <div class="digital-top-contant">
            <div class="row align-items-lg-center ">
                <div class="col-lg-6 col-md-12">
                    <div class="" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                        <div class="digital-image text-center ">
                            <img alt="centered image" src="assets/img/plusmax-logo-new.png" height="40%" width="40%"
                                alt="Plus Max">
                        </div>
                    </div>&nbsp;
                    <div style="font-size: 1.2em;">
                        <p data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500"><i
                                class='bx bxs-plane-alt'
                                style="color:cornflowerblue ; font-size: 1.3em; "></i>&nbsp;<strong class="text-white">
                                Established in 1989 as Agate Duty-Free, it has since been renamed Plus Max and has
                                expanded its horizons under the
                                leadership of our Managing Director, Dato Prakadeesh Kumar.</strong></p>
                        <p data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500"><i
                                class='bx bxs-plane-alt' style="color:red ; font-size: 1.3em;"></i>&nbsp;<strong
                                class="text-white"> Everything we work towards is built on a commitment to provide the
                                best for our customers. This is reflected in the
                                Mission and Vision statement, which guides the way we do business.</strong></p>
                    </div>
                </div>
                <div style="font-size: 1.2em;" class="col-lg-6 col-md-6">
                    <div class="digital-text ">
                        <p data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"><i
                                class='bx bxs-plane-alt' style="color:red ; font-size: 1.3em;"></i>&nbsp;<strong
                                class="text-white">Plus Max has maintained robust growth and contributed
                                significantly in its regional markets, paving the way for its
                                global expansion. We set our footprints in Dubai, India, Singapore, Hong Kong, and
                                Rwanda with our innovative products,
                                that are tailored and engineered to maximize comfortability and optimize responses,
                                based on the countries.</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="about-text-area ptb-100">
        <div id="particle-container">
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
        </div>
        <!-- <div class="bg bg2"></div>
        <div class="bg bg3"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div> -->
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 ">
                <div class="about-safe-text ">
                    <h2 style="color:#006400
                             ;">PLUS MAX GROUP OF COMPANIES</h2>
                    <strong> Be the leading global player in every sector by adopting best practices that
                        anticipate
                        and
                        satisfy people's desire and needs
                    </strong>
                    <h2 class="mb-2"><b>Dato' S. Prakadeesh Kumar</b></h2>
                    <h3 class="mb-3"><b>GROUP MANAGING DIRECTOR</b></h3>
                </div>
            </div>
            <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                class="col-lg-6 col-md-12">
                <div class="safe-image ">
                    <img src="assets/img/Info/dato_pic.jpg" alt="Dato Prakadeesh Kumar">
                </div>
            </div>
        </div>
    </div>
</div>
