import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-retail-sales',
    templateUrl: './retail-sales.component.html',
    styleUrls: ['./retail-sales.component.scss']
})
export class RetailSalesComponent implements OnInit {

    constructor(private backend: BackendApiService) { }

    ngOnInit(): void {
        this.backend.createVisitorLogs('Retail-Sales-page');
    }

}
