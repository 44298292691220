import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss'],
})
export class HomeOneComponent implements OnInit {
    constructor(private backend: BackendApiService) { }
    ngOnInit(): void {
        this.backend.createVisitorLogs('home-page');
    }
}
