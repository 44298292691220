<head>
    <link rel="canonical" href="https://plusmax.com.my/#/blog" />
</head>
<div class="page-banner jon-bg-1 mt-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Our Journey</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="700" class="section-title">
    <h2>Our Momentous Journey Path </h2>
</div>
<svg class="arrows">
    <path class="a1" d="M0 0 L30 32 L60 0"></path>
    <path class="a2" d="M0 20 L30 52 L60 20"></path>
    <path class="a3" d="M0 40 L30 72 L60 40"></path>
</svg>
<br>
<div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="700" id="sandbox">
    <div style="background-color:#dd202d">P</div>
    <div style="background-color:#6cbb93">L</div>
    <div style="background-color:#f0d095">U</div>
    <div style="background-color:#e0e334">S</div>
    <div style="background-color:#5756a4">M</div>
    <div style="background-color:#6ac5da">A</div>
    <div style="background-color:#7abc4a">X</div>
</div>
<div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="700" class="container mt-5">
    <div class="container fon">
        <div class="row">
            <div class="col">
                <div class="main-timeline">
                    <div class="timeline">
                        <a data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">1989</span>
                            <div class="timeline-icon">
                                <i class="fa fa-rocket" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h3 class="title">THE BEGINNING</h3>
                                <p class="description">
                                    Our organization commenced its business in the travel and retail industry in 1989
                                    under the banner
                                    of Agate Duty Free, where we pioneered the concept of a “Duty Free Plaza” in
                                    Malaysia. We had
                                    opened our very first outlet in the Subang International Airport, until operational
                                    changes required
                                    us to migrate to the Kuala Lumpur International Airport in 1996.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2005</span>
                            <div class="timeline-icon">
                                <i class="fa fa-users" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h3 class="title">THE GROUP</h3>
                                <p class="description">
                                    In 2005 we rebranded to our current corporate identity, Plus Max. We have grown from
                                    a humble
                                    travel retail entity to a leading organization in wholesale, trading, export of
                                    goods, infrastructure
                                    consultation and management, oil and gas services and engineering, information
                                    technology,
                                    logistics, warehousing, hotels and property management, education, and
                                    manufacturing. Our
                                    primary commodities are perfumery, tobacco, and alcoholic beverages.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2007</span>
                            <div class="timeline-icon">
                                <i class="fa fa-cog" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h3 class="title">BUSINESS</h3>
                                <p class="description">
                                    Culminating from our successful business history, hands-on expertise, and management
                                    skills, we
                                    were able to diversify our line of business to include logistics and warehousing,
                                    distribution and
                                    wholesale, oil and gas engineering, hotels and property management, education, and
                                    manufacturing.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2012</span>
                            <div class="timeline-icon">
                                <i class="fa fa-heart" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h3 class="title">WHOLESALE TRADING & EXPORT</h3>
                                <p class="description">
                                    Plus Max has established itself as a credible and exemplary wholesale trader. It is
                                    important to successfully operate a wide enough
                                    distribution
                                    network in the business of wholesale trading, export and import. Our network is
                                    supported with
                                    state of the art private bonded warehouses that are fully equipped, together with a
                                    systematic
                                    logistics set-up.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2015</span>
                            <div class="timeline-icon">
                                <i class="fa fa-globe" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h3 class="title">Our Logistics Network</h3>
                                <p class="description">
                                    Our group owns warehouses, fleet of lorries, container trucks, and logistics
                                    facilities in Peninsular
                                    Malaysia, Singapore, India, Hong Kong, Rwanda, and Dubai. As listed as a table
                                    below, these
                                    warehouses are rented as bonded or otherwise.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2018</span>
                            <div class="timeline-icon">
                                <i class="fa fa-apple" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h3 class="title">DUTY-FREE & WAREHOUSING</h3>
                                <p class="description">
                                    The business of Duty-free emanated from our growth as a travel retailer, and
                                    post-rebranding we
                                    have expanded domestically to nine outlets; located as follows:
                                </p>
                                <ul class="description fon">
                                    <li>Seaports in Port Klang Cruise Terminal in Klang Port and
                                        Kuantan Port</li>
                                    <li> Downtown duty-free outlets in Kuala Lumpur and two in
                                        Selangor (to cater to embassy
                                        and consulate offices)</li>
                                    <li>Passenger train terminals in Padang Besar and Pasir Gudang
                                        Passenger Terminal</li>
                                    <li>Duty-free Island in Langkawi</li>
                                    <li>Duty-Free in Forest City, Johor.</li>
                                </ul>
                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2019</span>
                            <div class="timeline-icon">
                                <i class="fa fa-apple" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h3 class="title">OIL & GAS</h3>
                                <p class="description">
                                    It has been almost two decades since the inception of Growth Avenue Sdn Bhd (GASB),
                                    with first
                                    venturing into the infrastructure and the construction business. Since then, we have
                                    gone by leaps
                                    and bounds into a new area of industry; in order to diversify our business
                                    portfolio. We have built
                                    a solid reputation for ourselves in the oil and gas industry (O & G). Providing
                                    consultation and
                                    implementation services while encompassing many different areas, we have grown from
                                    strength
                                    to strength through demonstrating our capabilities.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2021</span>
                            <div class="timeline-icon">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h3 class="title">HOSPITALITY</h3>
                                <p class="description">
                                    Cahaya Armada Sdn Bhd owns and operates a boutique 3-Star hotel, Royal Agate Beach
                                    Resort,
                                    in Pantai Cenang, Langkawi. Its iconic roof top restaurant has become a destination
                                    for all visitors
                                    to watch and experience the sunset from its restaurant. Our resort comes complete
                                    with various
                                    amenities and facilities, so holiday goers would have a memorable and unforgettable
                                    stay with us.
                                    Our resort is perfect, be it for family or young couples, for a quick getaway and
                                    romantic interlude.
                                    Our resort sits right in between affordability and comfort, warranting patrons a
                                    comfortable and
                                    relaxing stay with us; an approach that has resulted in high average room occupancy.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2022</span>
                            <div class="timeline-icon">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h6 class="title">Manufacturing</h6>
                                <p class="description">Plus Max has acquired land for establishing premier integrated
                                    Bioethanol, Beverages, and Specialty Bio-chemicals manufacturing plant.</p><br>

                            </div>
                        </a>
                    </div>
                    <div class="timeline">
                        <a data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2023</span>
                            <div class="timeline-icon">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon">
                                <h6 class="title">Plus Max FPO (for Farmers' Welfare)</h6>
                                <p class="description">Plus Max Farmers Producer Organization is a non-profit Organization incorporated in the year 2023, in Poolambadi Village, Perambalur District, Tamilnadu, India. The primary purpose of FPO is
                                    to unite and integrate all the Farmers in and around Perambalur District
                                    and guide them throughout the crop cultivation cycle</p><br>

                            </div>
                        </a>
                    </div>
                    <!-- <div class="timeline">
                        <a data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2024</span>
                            <div class="timeline-icon">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-right" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon"><br>
                                <h6 class="title">PLUS MAX VALUE MOUNT LIXIVIATE PVT. LTD</h6>
                                <p class="description">y</p><br>
                                <h6 class="title" data-aos="fade-right" data-aos-easing="linear"
                                    data-aos-duration="700">
                                    Our Successful Journey Continues.........<i class="bx bx-run bx-fade-right"
                                        style="font-size: 1.4em;"></i>
                                </h6>
                            </div>
                        </a>
                    </div> -->
                    <div class="timeline">
                        <a data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                            class="timeline-content">
                            <span class="timeline-year">2024</span>
                            <div class="timeline-icon">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700"
                                class="content fon"><br>
                                <h6 class="title">VALUE MOUNT LIXIVIATE PVT. LTD</h6>
                                <p class="description">Plus Max Group ventured into Gold Industry as a forward-thinking player, committed to leveraging advanced technologies to extract and refine precious metals efficiently. The sophisticated factory has been set up 
                                    in Ranipet Industrial Estate, Vellore, Tamilnadu, India, with state-of-the-art technology and laboratory facilities, making it a hydrometallurgical plant designed under the latest global advancements. The initial production capacity 
                                    is planned at 3000 tonnes of raw materials per month to produce a monthly output of 600 to 1000 Kilograms of Gold and 1500 to 3000 Kilograms of Silver.</p><br>
                                <h6 class="title">Plus Max Global Foods PVT LTD</h6>
                                <p class="description">Plus Max Global Foods Private Limited is embarking on its first venture into the global egg processing industry and is striving to serve the global protein industry by adopting advanced manufacturing techniques and cutting-edge technology in order to provide high-quality, hygienic and safe food products to customers.</p><br>
                                <h6 class="title" data-aos="fade-left" data-aos-easing="linear"
                                    data-aos-duration="700">
                                    Our Successful Journey Continues.........<i class="bx bx-run bx-fade-right"
                                        style="font-size: 1.4em;"></i>
                                </h6>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
