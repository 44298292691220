<header class="header-area">
    <div class="top-header">
        <div class="container ">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="left-info">
                        <li data-aos="flip-right"><a href="mailto:mail@plusmax.com.my"><i class='bx bxs-envelope'></i>
                                mail@plusmax.com.my</a></li>
                        <li data-aos="flip-right"><a href="tel:+(60)-320266100"><i
                                    class='bx bxs-phone-call'></i>+(60)-320266100</a></li>
                    </ul>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <ul class="right-info">
                        <li data-aos="flip-right"><a href="https://www.facebook.com/login/" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li data-aos="flip-right"><a
                                href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D"
                                target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li data-aos="flip-right"><a
                                href="https://www.linkedin.com/checkpoint/rm/sign-in-another-account" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>
                        <li data-aos="flip-right"><a href="https://www.instagram.com/accounts/login/" target="_blank"><i
                                    class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/home"><img src="assets/img/plusmax-logo-new.png" width="48" alt="logo"></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" data-aos="flip-right" routerLink="/home"><img
                            src=" assets/img/plusmax-logo-new.png" width="80" alt="logo"></a>
                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav ms-auto">
                            <li data-aos="flip-left" class="nav-item">
                                <a routerLink="/home" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" href="javascript:void(0)"
                                    class="nav-link"><i class='bx bx-home-smile'>&nbsp;</i>Home</a>
                            </li>
                            <li data-aos="flip-left" class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"><i class='bx bx-trending-up'></i> About
                                    Us</a>
                                <ul data-aos="flip-left" class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                                                class="bx bxs-business"></i>Our
                                            Vision</a></li>
                                    <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                                                class="bx bx-award"></i>Awards</a></li>
                                    <li class="nav-item"><a routerLink="/gallery" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                                                class="bx bx-photo-album"></i>Gallery</a></li>
                                </ul>
                            <li data-aos="flip-left" class="nav-item"><a routerLink="/services" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                                        class='bx bx-buildings'>&nbsp;</i>Business
                                    Entities</a></li>
                            <li data-aos="flip-left" class="nav-item"><a routerLink="/blog" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                                        class='bx bxs-plane-take-off'>&nbsp;</i>Who
                                    We Are</a></li>
                            <li data-aos="flip-left" class="nav-item"><a routerLink="/csr" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                                        class='bx bx-donate-heart'>&nbsp;</i>CSR</a>
                            </li>
                            <li data-aos="flip-left" class="nav-item"><a routerLink="/careers" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                                        class='bx bx-book-open'>&nbsp;</i>Careers</a>
                            </li>
                            <li data-aos="flip-left" class="nav-item"><a routerLink="/contact" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i
                                        class='bx bxs-contact'>&nbsp;</i>Contact
                                    Us</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
