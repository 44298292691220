<head>
    <link rel="canonical" href="https://plusmax.com.my/#/fpo" />
</head>
<div class="page-banner bg-17 mt-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 class="text-white">Plus Max FPO</h2>
                    <ul>
                        <li><a class="text-white" routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12"
                style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: medium;">
                <div class="service-details-text">
                    <h3>Farmers Producer Organization</h3>
                    <div class="fon">
                        <div class="service-image">
                            <!-- <img src="assets/img/f4.jpg" alt="Farmers Producer Organization"> -->
                        </div>
                        <p><i class='bx bxl-mangodp'
                                style="color:rgb(98, 212, 27); font-size: 1.2em;"></i>&nbsp;<strong>Plus Max Farmers
                                Producer Organization (FPO) was incorporated in the year 2023, in Poolambadi Village,
                                Perambalur District, Tamilnadu, India. The primary purpose of FPO is to unite and
                                integrate all the Farmers
                                in and around Perambalur District and guide them throughout the crop cultivation cycle.
                            </strong></p>
                        <p><i class='bx bxs-tree'
                                style="color:rgb(98, 212, 27); font-size: 1.2em;"></i>&nbsp;<strong>Currently more than
                                500 farmers have registered with Plus Max FPO for exporting their produce, thus
                                helping them to fetch a good price for their hard work. The pioneer farmers who are
                                cultivating crops such
                                as vegetables, fruits and flowers will be organized into village-wise farmer producer
                                groups of 20 members
                                and head for each group.
                            </strong></p>
                        <div class="image">
                            <img src="assets/img/f3.jpg" alt="Farmers Producer Organization">
                        </div>
                        <p><i class='bx bxs-tree'
                                style="color:rgb(98, 212, 27); font-size: 1.2em;"></i>&nbsp;<strong>Plus Max FPO will
                                support and guide the farmers with the following:
                            </strong></p>
                        <p><i class='bx bxs-tree'
                                style="color:rgb(98, 212, 27); font-size: 1.2em;"></i>&nbsp;<strong>The land of farmers
                                in the villages around Poolambadi has fertile soil and water resources suitable for
                                cultivation of agricultural crops and vegetable crops.
                            </strong></p>

                        <p>&nbsp;<strong>
                                ❖ Provide the farmers with the latest available cultivation technologies for best yields
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖ Support them the entire crop cultivation cycle including post-harvest support
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖  Testing the soil type, fertility and accordingly advise the crop types
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖ Testing the water type, salinity and accordingly suggest irrigation system
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖ Guide the farmers to procure the best seeds & saplings from the Government
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖ Guide the eligible farmers to obtain various agricultural schemes from Government Entities and Banks
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖ Providing training to all the group farmers in the Farmers Producers Company on the
                                techniques
                                of vegetable cultivation by the officers of the Agriculture Farmers' Welfare Departments
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖ Advise farmers to adopt integrated pest and disease management practices
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖ Providing Drone Technology & Utilization for crop inspection and fertilizer/pesticide
                                spraying
                            </strong></p>
                        <p>&nbsp;<strong>
                                ❖ Recommend crop rotation, intercropping and intercropping techniques to preserve soil
                                fertility and reduce fertilizer costs
                            </strong></p>
                        <div class="image">
                            <img src="assets/img/f5.jpg" alt="FPO">
                        </div>
                        <p><i class="bx bxs-tree"
                                style="color:rgb(98, 212, 27); font-size: 1.2em;"></i>&nbsp;<strong>Plus Max FPO is
                                very confident that with the help of various Government's Agricultural Schemes can
                                enhance the quality of Farmer’s life by providing full support and assistance throughout
                                the crop
                                cultivation cycle.
                            </strong></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Plus Max FPO</h3>
                    <ul>
                        <li><a><i class='bx bxs-tree'></i>FPO</a></li>
                    <img src="assets/img/PLUS MAX FPO LOGO.png" alt="image">


                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
