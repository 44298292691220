import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ServiceResult } from '../services/common';

@Injectable({
    providedIn: 'root',
})
export class BlobStorageService {
    private url = environment.apiUrl;
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
        }),
    };
    constructor(private http: HttpClient) { }
    uploadSingleFile(fs: File): Observable<any> {
        debugger;
        const formData = new FormData();
        formData.append('file', fs, fs.name);
        return this.http.post<any>(`${this.url}/api/careers/upload-file?containerName`, formData, { reportProgress: true, observe: 'events' });
    }
    public checkFileSize(bytes: number) {
        const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
        if (bytes === 0) return '0 bytes';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        if (i === 0) return `${bytes} ${sizes[i]})`;
        return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    }
}
