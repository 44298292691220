import { ViewportScroller } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Notify } from 'notiflix';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { Careers } from 'src/app/services/careers.model';
import { BlobStorageService } from 'src/app/storage/blob-storage.service';
@Component({
    selector: 'app-careers',
    templateUrl: './careers.component.html',
    styleUrls: ['./careers.component.scss'],
})
export class CareersComponent implements OnInit, AfterViewInit {
    @ViewChild('canvasEl') canvasEl: ElementRef;
    @ViewChild('inputFile') myInputVariable: ElementRef;
    uploadedImage: any;
    uploadedfile = '';
    fileUpload: File | null = null;
    progress: number = 0;
    message: string = ''
    dotnetContent: string[] = [];
    angularContent: string[] = [];
    networkContent: string[] = [];
    systemContent: string[] = [];
    reactContent: string[] = [];
    testingContent: string[] = [];
    jobContent: string[] = [];
    nbfcContent: string[] = [];
    showMore1 = false;
    showMore2 = false;
    showMore3 = false;
    showMore4 = false;
    showMore5 = false;
    showMore6 = false;
    showMore7 = false;
    showMore8 = false;
    constructor(private formBuilder: FormBuilder,
        private backend: BackendApiService,
        private blobStorageService: BlobStorageService,
        private snap: ActivatedRoute,
        private scroller: ViewportScroller,) { }
    private context: CanvasRenderingContext2D;
    careersmodel: Careers = {
        Id: 0,
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        Gender: true,
        MobileNumber: '',
        DocumentPath: '',
        JobTitle: '',
    };
    ngAfterViewInit(): void {
        this.context = (this.canvasEl.nativeElement as HTMLCanvasElement).getContext('2d');
        this.draw(this.createCaptcha());
        setTimeout(() => {
            this.scroller.scrollToAnchor('carrer-jobs');
        }, 2000);
    }
    private draw(text: any) {
        this.context.font = '30px Arial';
        this.context.textBaseline = 'middle';
        this.context.textAlign = 'center';
        const x = (this.canvasEl.nativeElement as HTMLCanvasElement).width / 2;
        const y = (this.canvasEl.nativeElement as HTMLCanvasElement).height / 2;
        this.context.clearRect(x, y, x, y);
        this.context.strokeText(text, x, y);
    }
    submitted = false
    careerForm: FormGroup;
    ngOnInit(): void {
        this.dotnetContent = [
            `1+ years of experience in backend development/ Full Stack development in HTML,CSS,JAVASCRIPT,C#,SQL.`,
            `Good knowledge of the .NET Core 3.1/5.0 Framework, including Visual Studio 2019/2022, relational database, MVC Application,REST WEB APIs.`,
            `Proven experience in building distributed, tier-1 systems. (High level design, low level design, design patterns)`,
            `Self-starter that demonstrates initiative and ability to work independently with minimal direction.`,
            `Enhance existing systems by analyzing business objectives, preparing an action`,
            `Plan and identifying areas for modification and improvement`,
            `Must understand and follow secure development practices to ensure sensitive data is secure from end-to-end.`,
            `Additional Knowledge on Azure Cloud, Azure Devops, AWS, Software Testing Tool like SonarQube`,
        ];
        this.angularContent = [
            `1+ years of experience in Full Stack development in Angular 7/ Angular 8/ Angular 9/Angular 10/ Angular 12.`,
            `Understanding of full stack development`,
            `In-depth knowledge of design issues and best practices.`,
            `Solid understanding of object-oriented programming. Familiar with various design, architectural patterns, and software development process.`,
            `Ensure high performance of all systems developed`,
            `Good understanding on Prototype chain and inner workings of inheritance in JS. Understood DOM, Events, Event Bubbling and Capturing/ ability to demonstrate it with example/ Promises and their use cases`,
            `Ability to contribute in medium to complex tasks independently`,

        ];
        this.networkContent = [
            `1+ years of experience in Network Engineer to Managing monitoring system performance and ensuring reliability and availability.`,
            `Installing, configuring and maintaining operating systems, application software and hardware`,
            `Continual maintenance of security, including backup and redundancy strategies`,
            `Monitor network performance (availability, utilization, throughput, goodput, and latency) and test for weaknesses`,
            `Ensure security through access controls, backups and firewalls`,
            `Hands-on experience in Securing Office networks, servers, workstations using various Security Tools like Firewall, Antivirus, etc.`,
            `Configure network hardware such as servers, routers, and switches.`,
            `Managing Network storage like NAS, SAN, etc.`,
            `Troubleshoot various network issues. Assist network architects with the design of network models whenever needed`,
            `Strong expertise in TCP, IPv4/6, and dynamic routing protocols such as BGP.`,
            `Hands on experience in VPN, LAN, WAN, NAC (CPPM,ISE)`
        ]
        this.systemContent = [
            `1+ years of experience in System Administrator to Install and configure computer hardware, operating systems and applications`,
            `Basic hardware and software troubleshooting for all desktops and Headphones.`,
            `Creating Users and assigning permission to the users for accessing the Active Directory`,
            `Network level troubleshooting on local connectivity.`,
            `Install and configure printers and outlook mail configurations.`,
            `Configure antivirus software to fully protect IT environment.`,
            `Monitor the performance of the computer systems and address issues as they arise.`,
            `Provide technical support for VOIP software reconfiguration to aid in function customization.`,
            `Monitoring the status of WSUS patches from console.`,
            `Configured and maintain the FTP Server and share folders`,
            `To check the DC Health and Availability Status.`,
            `Monitoring & maintaining threshold on HDD space & CPU utilization for servers.`,
            `Basic hardware troubleshooting for servers.`,
            `Basic working knowledge MS excel and WDS server.`,
        ];
        this.reactContent = [
            `1+ years of experience in React Developer to Reviewing application requirements and interface designs. Discuss user interface ideas and applications.`,
            `Identifying web-based user interactions. Developing and implementing highly responsive user interface components using react concepts.`,
            `Developing and implementing front-end architecture to support user interface concepts.`,
            `Translating designs and wireframes into high-quality code.`,
            `You have experience with front-end development (Typescript, JSES6, SASS CSS, HTML)`,
            `Strong experience with popular React.js workflows. Experience with React Context API, Redux.`,
            `Experience with RESTful APIs`,
            `Knowledge of modern authorization mechanisms, such as JSON Web Token`,
            `Familiar with code versioning tools (such as Git/GitLab/bitbucket)`
        ];
        this.testingContent = [
            `1+ years of experience in Software Testing of experience with Javascript, Java, or Scala`,
            `Good understanding of test-driven development, developing different levels of automated tests, such as unit tests, integration tests, and functional tests.`,
            `Deep knowledge of manual testing and best practices around test engineering.`,
            `Comfortable with navigating complex topics and using data to make decisions`,
            `Design and deliver risk-based test plans for new features and functionality, choosing the right testing method for each situation or phase (scenario, exploratory, scripted, manual, automated, etc.)`,
            `Be a reliable, friendly source of feedback on new functionality, features, and process changes.`,
            `Awe your team by uncovering or, better yet, preventing corner case defects.`,
            `Be a quality advocate, collaborating closely with software engineers, designers, product managers, etc. to release life-changing products.`,
            `SDLC Software Engineering concepts.`
        ]
        this.jobContent = [
            `Corporate Office
             Job Location - Tiruchengode (Namakal Dt.)`,
            `Charted Accountant (3 yrs Exp.)`,
            `Company Secretary (1 yrs Exp.)`,
            `HR (Min 3 yr Exp.)`,
            `Accounts Manager(3 yrs Exp.)`,
            `Senior Accounts Executive(2 yrs Exp.)`,
            `Accounts Executive (1 yr Exp.)`,
        ]
        this.nbfcContent = [
            `NBFC Sector
            Job Location - Poolambadi - Perambalur Dt,
                         `,
            `Karumandurai - Salem Dt.`,
            `Branch Manager (Min 3 yrs Exp in NBFC Sector)`,
            `Senior Relationship Executive (2 yrs Exp.)`,
            `Junior Relationship Executive (1 yr Exp.)`
        ]
        const PAT_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((x) => { });
        }
        this.backend.createVisitorLogs('careers-page');
        this.careerForm = this.formBuilder.group({
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            EmailAddress: ['', Validators.pattern(PAT_EMAIL)],
            Gender: [false],
            MobileNumber: ['', Validators.required],
            captchaReader: ['', Validators.required],
            JobTitle: ['', Validators.required],
            DocumentPath: ['']
        });
        this.createCaptcha();
    }
    showMoreFn1() {
        this.showMore1 = !this.showMore1;
    }
    showMoreFn2() {
        this.showMore2 = !this.showMore2;
    }
    showMoreFn3() {
        this.showMore3 = !this.showMore3;
    }
    showMoreFn4() {
        this.showMore4 = !this.showMore4;
    }
    showMoreFn5() {
        this.showMore5 = !this.showMore5;
    }
    showMoreFn6() {
        this.showMore6 = !this.showMore6;
    }
    showMoreFn7() {
        this.showMore7 = !this.showMore7;
    }
    showMoreFn8() {
        this.showMore8 = !this.showMore8;
    }
    code: any;
    createCaptcha() {
        let charsArray = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*';
        let lengthOtp = 6;
        let captcha = [];
        for (let i = 0; i < lengthOtp; i++) {
            let index = Math.floor(Math.random() * charsArray.length + 1);
            if (captcha.indexOf(charsArray[index]) == -1)
                captcha.push(charsArray[index]);
            else i--;
        }
        this.code = captcha.join('');
        return this.code;
    }
    onSubmitCarrer(event: any) {
        this.submitted = true;
        if (this.careerForm.invalid) {
            Notify.warning('Please fill the required fields.')
            return;
        }
        this.blobStorageService.uploadSingleFile(this.fileUpload).subscribe((dt: any) => {
            if (dt.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * dt.loaded) / dt.total);
            } else if (dt.type === HttpEventType.Response) {
                this.progress = 100;
                this.prepareSaveData(dt.body.response);
            }
        })
    }
    prepareSaveData(cv_path: string) {
        if (this.careerForm.value.captchaReader == this.code) {
            this.careersmodel = this.careerForm.value;
            this.careersmodel.DocumentPath = cv_path;
            const body = JSON.stringify(this.careersmodel);
            this.backend.createCareer(body).subscribe((x) => {
                this.myInputVariable.nativeElement.value = '';
                this.careerForm.reset();
                Notify.success('Submitted SuccessFully');
            });
        } else {
            const x = (this.canvasEl.nativeElement as HTMLCanvasElement).width;
            const y = (this.canvasEl.nativeElement as HTMLCanvasElement).height;
            this.context.clearRect(0, 0, x, y);
            this.draw(this.createCaptcha());
            Notify.failure('Check the Fields')
        }
    }
    get f(): { [key: string]: AbstractControl } {
        return this.careerForm.controls
    }
    onChange(event: any): any {
        const allowed_images_types = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'];
        const file_extension = ['mb', 'gb', 'tb'];
        let fileSize = this.blobStorageService.checkFileSize(event.target.files[0].size);
        let fsize = fileSize.split(' ')[0];
        let fextension = fileSize.split(' ')[1].toLowerCase();
        if (Number(fsize) > 2 && file_extension.includes(fextension)) {
            Notify.info('Maximum size allowed is ( 2 MB ) only.');
            return false;
        }
        if (!allowed_images_types.includes(event.target.files[0].type)) {
            Notify.info('Only PDF are allowed ');
            return false;
        }
        this.fileUpload = null;
        this.fileUpload = event.target.files[0];
    }
    projectcount: number = 0;
    accuratecount: number = 0;
    clientcount: number = 0;
    customerfeedback: number = 0;
    workers: number = 0;
    projectcountstop: any = setInterval(() => {
        this.projectcount++;
        if (this.projectcount == 55) {
            clearInterval(this.projectcountstop);
        }
    }, 100);
    accuratecountstop: any = setInterval(() => {
        this.accuratecount++;
        if (this.accuratecount == 95) {
            clearInterval(this.accuratecountstop);
        }
    }, 10);
    clientcountstop: any = setInterval(() => {
        this.clientcount++;
        if (this.clientcount == 100) {
            clearInterval(this.clientcountstop);
        }
    }, 10);
    customerfeedbackstop: any = setInterval(() => {
        this.customerfeedback++;
        if (this.customerfeedback == 25) {
            clearInterval(this.customerfeedbackstop);
        }
    }, 100);
    workersstop: any = setInterval(() => {
        this.workers++;
        if (this.workers == 100) {
            clearInterval(this.workersstop);
        }
    }, 100);
}
