<head>
    <link rel="canonical" href="https://plusmax.com.my/#/realestate" />
</head>
<div class="page-banner bg-12">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container ">
                <div class="page-content">
                    <h2>Real Estate</h2>
                    <ul>
                        <li><a routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="fon">
                        <h3>
                            <h2>Real Estate - Malaysia</h2>
                        </h3>
                        <p class="fon"><i class='bx bx-building-house'
                                style="color:red; font-size: 1.2em;"></i>&nbsp;<strong>Plus Max Real Estate has
                                successfully acquired a 208-acre parcel of land in Seberang Prai, Penang, situated on
                                Peninsular Malaysia's northwest coast.
                            </strong></p>
                        <p><i class='bx bx-building-house' style="color:red; font-size: 1.2em;"></i>&nbsp;<strong>We
                                intend to transform and cultivate the area
                                into an Industrial Park in
                                collaboration with reputable local developer.</strong></p>
                        <h2>Real Estate - India</h2>
                        <div class="service-image">
                            <img src="assets/img/gallery/Prime.jpg" alt="Prime City">
                        </div>
                        <br>
                        <p><i class='bx bx-building-house' style="color:red; font-size: 1.2em;"></i>&nbsp;<strong>We
                                have successfully secured an industrial plot measuring 400 acres in Arakonam District,
                                Tamilnadu, suitable for
                                developing into various Industrial Sectors.</strong></p>
                    </div>
                </div>
                <div class="service-image">
                    <img src="assets/img/gallery/Prime2.jpg" alt="Prime City">
                </div>
                <!-- <div class="service-image">
                    <img src="assets/img/Real-Estate/Arokanom3.jpg" alt="image">
                </div> -->
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Real Estate</h3>
                    <ul>
                        <li><a><i class='bx bxs-building-house'></i>Properties</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
