export class VisitorLog {
    public ipAddress: string;
    public country: string;
    public city: string;
    public coordinates: string;
    public zipCode: string;
    public timeZone: string;
    public page: string;
    public visitedDateTime: string;
    public brand: string;
    public operatingSystem: string;
    public browser: string;
    public userAgent: string;
    public year: number;
    public osVersion: string;
    public deviceType: string;
}
export class IPInformation {
    public ip: string;
    public city: string;
    public country: string;
    public loc: string;
    public postal: string;
    public region: string;
}
