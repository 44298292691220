import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { BackendApiService } from 'src/app/services/backend-api.service';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private backend: BackendApiService) { }

  ngOnInit(): void {
    AOS.init();
    this.backend.createVisitorLogs('vision-page');
  }
}
