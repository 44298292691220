<head>
    <link rel="canonical" href="https://plusmax.com.my/#/contact" />
</head>
<div class="page-banner con-bg-1 mt-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
            </div>
        </div>
    </div>
</div>
<div class="pt-100 pb-70 fon">
    <div class="container-fluid">
        <div class="row">
            <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class='bx bxs-phone'></i>
                    <h4>Contact Number</h4>
                    <p> <a href="tel:+(60)-320266100">+(60)-320266100</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                    class="contact-info">
                    <i class='bx bxs-location-plus'></i>
                    <h4>Our Location</h4>
                    <p>16th Floor, Menara Safuan, No.80, Jalan Ampang, <br>50450 Kuala Lumpur,<br> Malaysia.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                    class="contact-info">
                    <i class='bx bxs-envelope'></i>
                    <h4>Contact Mail</h4>
                    <p><a href="mailto:mail@plusmax.com.my">mail@plusmax.com.my</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact-form-area pb-100 fon">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Get in Touch</h2>
        </div>
        <div class="contact-form">
            <form id="contactForm" [formGroup]="contactForm">
                <div class="row">
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" formControlName="Name" class="form-control" placeholder="Your name"
                                [ngClass]="{ 'is-invalid': submitted && f.Name.errors }">
                            <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                                <div *ngIf="f.Name.errors.required">
                                    Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" formControlName="EmailAddress" class="form-control" required
                                placeholder="Your email address" [ngClass]="{
                                    'is-invalid':
                                      (submitted && f.EmailAddress.errors) ||
                                      (!f.EmailAddress.pristine && f.EmailAddress.invalid)
                                  }">
                            <div *ngIf="submitted && f.EmailAddress.errors" class="invalid-feedback">
                                <div *ngIf="f.EmailAddress.errors.required">
                                    EmailAddress is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" formControlName="Subject" class="form-control" placeholder="Your Subject"
                                [ngClass]="{ 'is-invalid': submitted && f.Subject.errors }">
                            <div *ngIf="submitted && f.Subject.errors" class="invalid-feedback">
                                <div *ngIf="f.Subject.errors.required">
                                    Subject is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" formControlName="MobileNumber" class="form-control"
                                placeholder="Your Mobile number" [ngClass]="{
                                    'is-invalid': submitted && f.MobileNumber.errors
                                  }"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');">
                            <div *ngIf="submitted && f.MobileNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.MobileNumber.errors.required">
                                    Mobile Number is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea formControlName="Description" class="form-control" cols="30" rows="6"
                                placeholder="Write your message..."></textarea>
                        </div>
                    </div>
                    <div id="captcha">
                        <canvas #canvasEl width="150" height="50"></canvas>
                    </div>
                    <div>
                        <input type="text" formControlName="captchaReader" autocomplete="off" placeholder="Captcha"
                            value="" id="captchaReader"
                            [ngClass]="{ 'is-invalid': submitted && f.captchaReader.errors }" />
                        <div *ngIf="submitted && f.captchaReader.errors" class="invalid-feedback">
                            <div *ngIf="f.captchaReader.errors.required">
                                Enter the Valid Captcha
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn-one" (click)="validateCaptcha($event)">Send
                            Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="maps">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.7617629012984!2d101.70043721467361!3d3.15739519770098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4922378f7e05%3A0x1f5c38e9a4d49fcb!2sPlus%20Max%20Group%20of%20Companies!5e0!3m2!1sen!2sbd!4v1645700112737!5m2!1sen!2sbd"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>
