import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-page-landing',
    templateUrl: './page-landing.component.html',
    styleUrls: ['./page-landing.component.scss'],
})
export class PageLandingComponent implements OnInit {

    constructor(private backend: BackendApiService) { }
    ngOnInit(): void {
        this.backend.createVisitorLogs('Pre-Loading-page');
    }
}
