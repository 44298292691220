<head>
    <link rel="canonical" href="https://plusmax.com.my/#/finance" />
</head>
<div class="page-banner bg-11">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container ">
                <div class="page-content">
                    <h2>Finance</h2>
                    <ul>
                        <li><a routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="fon">
                        <h3>
                            <h2>Finance</h2>
                        </h3>
                        <div class="service-image">
                            <img src="assets/img/Finance/Agate-Finance.jpg" alt="Agate Finance">
                        </div>
                        <p><i class='bx bx-money' style="color:red; font-size: 1.2em;"></i>&nbsp;<strong>Agate Finance
                                Limited (related company of the Plus Max Group), is a non-banking financial company
                                (NBFC) that provides a
                                diverse selection of loans. Agate Finance Limited's flagship branch is situated in
                                Poolambadi, Tamil Nadu.</strong></p>
                        <p><i class='bx bx-money' style="color:red; font-size: 1.2em;"></i>&nbsp;<strong>Agate Finance
                                is a
                                typical NBFC company that offers <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i
                                    class='bx bx-coin' style="color:red; font-size: 1.2em;"></i>&nbsp;&nbsp;Gold
                                Loan<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='bx bx-briefcase'
                                    style="color:red; font-size: 1.2em;"></i>&nbsp;&nbsp;Property
                                Loan <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='bx bx-car'
                                    style="color:red; font-size: 1.2em;"></i>&nbsp;&nbsp;Vehicle
                                Loan<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='bx bx-home'
                                    style="color:red; font-size: 1.2em;"></i>&nbsp;&nbsp;House Loan<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='bx bx-user'
                                    style="color:red; font-size: 1.2em;"></i>&nbsp;&nbsp;Personal Loan<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class='bx bx-user-plus'
                                    style="color:red; font-size: 1.2em;"></i>&nbsp;&nbsp;Group
                                Loan</strong></p>
                        <div class="service-image">
                            <img src="assets/img/Finance/DSC_1804.JPG" alt="Agate Finance">
                        </div>
                        <p><i class='bx bx-money' style="color:red; font-size: 1.2em;"></i>&nbsp;<strong>
                                We primarily cater to and provide financial assistance to underprivileged individuals in
                                Tamil Nadu. There has been an
                                escalating need to address the concerns of the rural population, which has been
                                underserved for years, which we bridge
                                by establishing a physical presence in such regions.
                            </strong> </p>

                        <p><i class='bx bx-money' style="color:red; font-size: 1.2em;"></i>&nbsp;<strong>We assist
                                financially backward communities, especially the women, by offering JLG (Joint Liability
                                Group), SHG (Self
                                Help Group), and loans to support them in fulfilling their dreams and guide them with
                                potential business opportunities.
                                We collaborate with clients to identify and develop strategies for transforming their
                                dreams into reality and seeing
                                them thrive over time.</strong>
                        </p>
                        <div class="service-image">
                            <img src="assets/img/Finance/DSC_1862.JPG" alt="Agate Finance">
                        </div>
                        <p><i class='bx bx-money' style="color:red; font-size: 1.2em;"></i>&nbsp;<strong>Our focus is on
                                strengthening our presence throughout our branch expansion program and assisting farmers
                                and
                                entrepreneurs with current credit needs. This ensures the growth and improvement of
                                agriculture, trade, and commerce
                                activities. We make financial services simple, convenient, and affordable by doing
                                so.</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <ul>
                        <li><a><i class='bx bx-coin'></i>NBFC Finance</a></li>
                        <li><a><i class='bx bx-money'></i>All type of Loans</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>