import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-csr',
    templateUrl: './csr.component.html',
    styleUrls: ['./csr.component.scss']
})
export class CSRComponent implements OnInit {

    constructor(private backend: BackendApiService) { }

    ngOnInit(): void {
        this.backend.createVisitorLogs('CSR-page');
    }

}
