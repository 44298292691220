import { Component, OnInit } from '@angular/core';
import {
    Router,
    NavigationStart, NavigationCancel, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute,
} from '@angular/router';

import { filter } from 'rxjs/operators';
import * as AOS from 'aos';
declare let $: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    isShowLanding: boolean = true;
    constructor(private router: Router, private snap: ActivatedRoute) { }
    ngOnInit() {
        AOS.init();
        this.recallJsFuntions();
        setTimeout(() => {
            this.isShowLanding = false;
            let navigate = window.location.href.split('#')[1];
            if (navigate == '/') {
                this.router.navigate(['/about']);
                this.router.navigateByUrl('/home');
            }
            else {
                this.router.navigate(['/about']);
                this.router.navigateByUrl(navigate);
            }
        }, 6000);
    }
    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe((event) => {
                $.getScript('../assets/js/custom.js');
                $('.preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
