import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Notify } from 'notiflix';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { Contact } from 'src/app/services/contact.model';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, AfterViewInit {
    @ViewChild('canvasEl') canvasEl: ElementRef;
    private context: CanvasRenderingContext2D;
    contactmodel: Contact = {
        Id: 0,
        Name: '',
        EmailAddress: '',
        Subject: '',
        MobileNumber: '',
        Description: '',
    };
    constructor(
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private backend: BackendApiService
    ) { }
    ngAfterViewInit(): void {
        this.context = (
            this.canvasEl.nativeElement as HTMLCanvasElement
        ).getContext('2d');
        this.draw(this.createCaptcha());
    }
    contactForm: FormGroup;
    ngOnInit(): void {
        const PAT_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';
        this.backend.createVisitorLogs('contact-page');
        this.contactForm = this.formBuilder.group({
            Name: ['', Validators.required],
            EmailAddress: ['', Validators.pattern(PAT_EMAIL)],
            Subject: ['', Validators.required],
            MobileNumber: ['', Validators.required],
            Description: [''],
            captchaReader: ['', Validators.required],
        });
    }
    get f(): { [key: string]: AbstractControl } {
        return this.contactForm.controls
    }
    submitted = false
    code: any;
    createCaptcha() {
        let charsArray =
            '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%&*';
        let lengthOtp = 6;
        let captcha = [];
        for (let i = 0; i < lengthOtp; i++) {
            let index = Math.floor(Math.random() * charsArray.length + 1);
            if (captcha.indexOf(charsArray[index]) == -1)
                captcha.push(charsArray[index]);
            else i--;
        }
        this.code = captcha.join('');
        return this.code;
    }
    private draw(text: any) {
        this.context.font = '30px Arial';
        this.context.textBaseline = 'middle';
        this.context.textAlign = 'center';

        const x = (this.canvasEl.nativeElement as HTMLCanvasElement).width / 2;
        const y = (this.canvasEl.nativeElement as HTMLCanvasElement).height / 2;
        this.context.clearRect(x, y, x, y);
        this.context.strokeText(text, x, y);
    }
    validateCaptcha(event) {
        this.submitted = true;
        if (this.contactForm.invalid) {
            Notify.warning('Please fill the required fields.')
            return;
        }
        event.preventDefault();
        if (this.contactForm.value.captchaReader == this.code) {
            this.contactmodel = this.contactForm.value;
            const body = JSON.stringify(this.contactmodel);
            this.backend.createContact(body).subscribe((x) => {
                this.contactForm.reset();
                Notify.success('Messsage Sended SuccessFully');
            });
        } else {
            const x = (this.canvasEl.nativeElement as HTMLCanvasElement).width;
            const y = (this.canvasEl.nativeElement as HTMLCanvasElement).height;
            this.context.clearRect(0, 0, x, y);
            this.draw(this.createCaptcha());
            Notify.failure('Something Went Wrong')
        }
    }
}
