import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

    constructor(private backend: BackendApiService) { }

    ngOnInit(): void {
        this.backend.createVisitorLogs('Gallery-page');
    }

}
