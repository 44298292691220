<head>
    <link rel="canonical" href="https://plusmax.com.my/#/infra" />
</head>
<div class="page-banner bg-15">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 class="text-black">Infrastructure</h2>
                    <ul>
                        <li><a class="text-black" routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12"
                style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: medium;">
                <div class="service-details-text">
                    <div class="fon">
                        <h3>Salem Site Project</h3>
                        <div class="service-image">
                            <img src="assets/img/Infra/SalemS4.jpeg" alt="PLUSMAX Infrastructure">
                        </div>
                        <p><i class='bx bx-building'
                                style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Plus Max
                                Infrastructure is actively
                                developing affordable housing in Southern Tamil Nadu, India, with 636 apartments in
                                Salem </strong></p>
                        <div class="service-image">
                            <img src="assets/img/Infra/SalemS5.jpeg" alt="PLUSMAX Infrastructure">
                        </div>
                        <h3>Thanjavur Site Project</h3>
                        <div class="image">
                            <img src="assets/img/Infra/Thanjavursite1.jpeg" alt="PLUSMAX Infrastructure">
                        </div>
                        <p><i class='bx bx-building'
                                style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Plus Max
                                Infrastructure is actively
                                developing affordable housing in Southern Tamil Nadu, India, with 969 apartments in
                                Thanjavur</strong></p>
                        <div class="image">
                            <img src="assets/img/Infra/Thanjavursite2.jpeg" alt="PLUSMAX Infrastructure">
                        </div>
                        <h3>Poolambadi Road Project</h3>
                        <div class="image">
                            <img src="assets/img/Infra/poolambadiroad.jpeg" alt="PLUSMAX Infrastructure">
                        </div>
                        <p><i class='bx bx-building'
                                style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Plus Max Group of
                                Companies adopted the
                                villages of Kadambur, Pudur, Arasadikadu and Melankudankadi, under the Perambalur
                                district,
                                Tamilnadu, India where the basic infrastructure such as road, water, electricity, etc.
                                are not available.</strong></p>
                        <div class="image">
                            <img src="assets/img/Infra/poolambadiroad1.jpeg" alt="PLUSMAX Infrastructure">
                        </div>
                        <p><i class='bx bx-building'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>As part of this drive under the Namakku Naame Thittam (We for
                            Ourselves Scheme),
                            initially Plus Max Infrastructure is constructing black top road connecting these
                            villages.</strong></p>
                        <div class="image">
                            <img src="assets/img/Infra/poolambadiroad2.jpeg" alt="PLUSMAX Infrastructure">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Infrastructure</h3>
                    <ul>
                        <li><a><i class='bx bx-buildings'></i>Infrastructure</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
