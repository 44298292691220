import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CareersComponent } from './components/pages/careers/careers.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ExportServicesComponent } from './components/pages/export-services/export-services.component';
import { OilGasComponent } from './components/pages/oil-gas/oil-gas.component';
import { InfotechComponent } from './components/pages/infotech/infotech.component';
import { RetailSalesComponent } from './components/pages/retail-sales/retail-sales.component';
import { CSRComponent } from './components/pages/csr/csr.component';
import { RealestateComponent } from './components/pages/realestate/realestate.component';
import { HospitalityComponent } from './components/pages/hospitality/hospitality.component';
import { FinanceComponent } from './components/pages/finance/finance.component';
import { PageLandingComponent } from './components/common/page-landing/page-landing.component';
import { ManufacturingComponent } from './components/pages/manufacturing/manufacturing.component';
import { InfraComponent } from './components/pages/infra/infra.component';
import { GoldoneComponent } from './components/pages/goldone/goldone.component';
import { FpoComponent } from './components/pages/fpo/fpo.component';
import { CovidCSRComponent } from './components/pages/covid-csr/covid-csr.component';
import { PoolambadiCsrComponent } from './components/pages/poolambadi-csr/poolambadi-csr.component';
@NgModule({
    declarations: [
        AppComponent,
        HomeOneComponent,
        PreloaderComponent,
        NavbarComponent,
        FooterComponent,
        AboutComponent,
        TeamComponent,
        TestimonialsComponent,
        GalleryComponent,
        ComingSoonComponent,
        ErrorComponent,
        ServicesComponent,
        ServicesDetailsComponent,
        BlogComponent,
        ContactComponent,
        CareersComponent,
        ExportServicesComponent,
        OilGasComponent,
        InfotechComponent,
        RetailSalesComponent,
        CSRComponent,
        RealestateComponent,
        HospitalityComponent,
        FinanceComponent,
        PageLandingComponent,
        ManufacturingComponent,
        InfraComponent,
        GoldoneComponent,
        FpoComponent,
        CovidCSRComponent,
        PoolambadiCsrComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatIconModule,
        BrowserAnimationsModule,
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatTabsModule,

    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule { }
