<head>
    <link rel="canonical" href="https://plusmax.com.my/#/testimonials" />
</head>
<div class="page-banner bg-8">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Awards</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Testimonials</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Awards</h2>
        </div>
        <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-card">
                <div class="img">
                    <img src="assets/img/awards/dato.JPG" alt="Dato Prakadeesh Kumar">
                    <h3>Dato' S. Prakadeesh Kumar</h3>
                    <span>Plus Max Group of Companies</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="feedback-text">
                    <p>In the year 2013 Dato' S. Prakadeesh Kumar was conferred the prestigious title <br>"DATO" by his
                        Royal
                        Highness Duli Yang
                        Maha Mulia Sultan Pahang Sultan Haji Ahmad Shah Al Musta' in Billah Ibni Almarhum Sultan
                        Abu
                        Bakar.</p>
                </div>
            </div>
            <div class="testimonials-card">
                <div class="img">
                    <img src="assets/img/awards/Rajarajachozhan2.jpg" alt="Rajaraja chozhan Award Prakadeesh Kumar">
                    <h3>Dato' S. Prakadeesh Kumar</h3>
                    <span>Plus Max Group of Companies</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="feedback-text">
                    <p>In the year 2022, Dato' S Prakadeesh Kumar received the most esteemed RAJARAJA CHOZHAN AWARD from
                        Bharathiyar College
                        Educational Group for constructing & inaugurating the Draupathi Amman Temple in Poolambadi,
                        Tamilnadu, India and for
                        sponsoring the complete school/college fees for many deserving & poor students.
                    </p>
                </div>
            </div>
            <div class="testimonials-card">
                <div class="img">
                    <img src="assets/img/awards/KonguAward.jpg" alt="Kongu Award Prakadeesh Kumar">
                    <h3>Dato' S. Prakadeesh Kumar</h3>
                    <span>Plus Max Group of Companies</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="feedback-text">
                    <p>In the year 2022, Dato' S Prakadeesh Kumar received the most prestigious KONGU MAMANI Award from
                        Kongu Nanbargal Sangam,
                        India for providing the best social services during the COVID-19 pandemic and for societal
                        growth.
                    </p>
                </div>
            </div>
            <div class="testimonials-card">
                <div class="img">
                    <img src="assets/img/awards/Times.jpeg" alt="Times award Prakadeesh">
                    <h3>Dato' S. Prakadeesh Kumar</h3>
                    <span>Plus Max Group of Companies</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="feedback-text">
                    <p>In the year 2021 Dato' S. Prakadeesh Kumar was awarded with the remarkable "EXCELLENCE IN
                        INTERNATIONAL TRADE
                        AWARD" by Times
                        Business Awards, India.</p>
                </div>
            </div>
            <div class="testimonials-card">
                <div class="img">
                    <img src="assets/img/awards/sonu.jpeg" alt="Dato Prakadeesh Kumar">
                    <h3>Dato' S. Prakadeesh Kumar</h3>
                    <span>Plus Max Group of Companies</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="feedback-text">
                    <p>In the year 2021 Dato' S. Prakadeesh Kumar was awarded with the remarkable "BEST CSR COMPANY OF
                        THE
                        YEAR AWARD"
                        by Times Group,
                        India.</p>
                </div>
            </div>
            <div class="testimonials-card">
                <div class="img">
                    <img src="assets/img/awards/gala.jpg" alt="ASEAN-INDIA EMERGING
                    ENTREPRENEUR ACHIEVEMENT Dato Prakadeesh Kumar">
                    <h3>Dato' S. Prakadeesh Kumar</h3>
                    <span>Plus Max Group of Companies</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div>
                <div class="feedback-text">
                    <p>In the year 2017 Dato' S. Prakadeesh Kumar was awarded with the prominent "ASEAN-INDIA EMERGING
                        ENTREPRENEUR ACHIEVEMENT &
                        EXCELLENCE AWARD" by<br> ASEAN India Business Council.</p>
                </div>
            </div>
            <div class="testimonials-card">
                <div class="img">
                    <img src="assets/img/awards/awds.jpg" alt="Dato' S. Prakadeesh Kumar">
                    <h3>Dato' S. Prakadeesh Kumar</h3>
                    <span>Plus Max Group of Companies</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div><br>
                <div class="feedback-text">
                    <p><i class='bx bxs-award' style="color:rgb(250, 81, 106) ; font-size: 1.4em; "></i>&nbsp;In the
                        year 2015 Dato' S. Prakadeesh Kumar was awarded with the most admired award "INTERNATIONAL
                        SUCCESSFUL BUSINESSMAN"
                        by South Indian Business Community.</p>
                </div><br>
                <div class="feedback-text">
                    <p><i class='bx bxs-award' style="color:rgb(250, 81, 106) ; font-size: 1.4em; "></i>&nbsp;In the
                        Same year 2015 Dato' S. Prakadeesh Kumar was awarded with the notable “HIGH ACHIEVERS AWARD“ by
                        Malaysia Namakkal
                        Welfare Association, Ipoh Branch, Perak, Malaysia.</p>
                </div>
            </div>
        </div>
    </div>
</div>