<head>
    <link rel="canonical" href="https://plusmax.com.my/#/csr" />
</head>
<div class="page-banner bg-7">
    <div class="d-table">
        <div class="d-table-cell text-black">
            <div class="container">
                <div class="page-content">
                    <h2 style="color: #1d124c;">Corporate Social Responsibility</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="our-services-area ptb-100">
    <div data-aos="fade-up" data-aos-duration="3000" class="container">
        <div class="">
            <h1>Corporate Social Responsibility</h1>
            <p><i class='bx bxs-donate-heart' style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>
                CSR projects entwine us to our environment and the community around us, allowing us to
                better
                understand the
                challenges they face and how we can provide integrated recovery and equitable access to
                all.</strong></p>
        <P><strong> Our initiatives, include but are not limited to the following:</strong></P>
        </div><br>
        <div class="row">
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/f4.jpg" alt="image">
                    <div class="service-caption">
                        <h3>Plus Max FPO (Farmers' welfare)</h3>
                        <p>
                            Plus Max Farmers Producer Organization (FPO)
                        </p>
                        <a routerLink="/fpo" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/Donation1.jpeg" alt="image">
                    <div class="service-caption">
                        <h3>CSR Services in India</h3>
                        <p>CSR</p>
                        <a routerLink="/poolambadicsr" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/CSR School.jpg" alt="image">
                    <div class="service-caption">
                        <h3>CSR - Covid Pandemic</h3>
                        <p>
                           Covid fund
                        </p>
                        <a routerLink="/covidcsr" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

        </div>
        <p><i class='bx bxs-donate-heart' style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Plus Max aspires to be a responsible and sustainable organization that balances economic,
            ecological, and social
            paradigms. We ensure that we always act ethically and abide by sustainable practices throughout
            our operations and
            distribution chain across all of our products and services in all of our industries.</strong></p>
    </div>


