<head>
    <link rel="canonical" href="https://plusmax.com.my/#/services-details" />
</head>
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <!-- <div class="container">
                <div class="page-content">
                    <h2>Education</h2>
                </div>
            </div> -->
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="fon">
                        <div class="service-image">
                            <img src="assets/img/Education/College3.jpg" alt="Plus Max Education">
                        </div>
                        <h3>Education</h3>
                        <p><i class='bx bx-book-open'
                                style="color:cornflowerblue; font-size: 1.1em;"></i>&nbsp;<strong>Plus Max has ventured
                                into the education sector by becoming a significant shareholder in India’s first women
                                engineering
                                college, Bharathiyar Institute of Engineering for Women.</strong></p>
                        <div class="image">
                            <img src="assets/img/Education/College1.jpg" alt="Plus Max Education">
                        </div>
                        <p><i class='bx bx-book-open' style="color:cornflowerblue; font-size: 1.1em;"></i>&nbsp;<strong>
                                The
                                university provides a wide range of courses for
                                bachelor’s and master’s degrees in engineering.</strong> </p>
                        <div class="image">
                            <img src="assets/img/Education/College2.jpg" alt="Plus Max Education">
                        </div>
                        <p><i class='bx bx-book-open'
                                style="color:cornflowerblue; font-size: 1.1em;"></i>&nbsp;<strong>Through this, we
                                intend to empower women from underprivileged backgrounds to be ethically strong
                                professionals who make
                                significant contributions to the society..</strong></p>
                        <div class="image">
                            <img src="assets/img/Education/College4.jpg" alt="Plus Max Education">
                        </div>
                        <p><i class='bx bx-book-open'
                                style="color:cornflowerblue; font-size: 1.1em;"></i>&nbsp;<strong>Our
                                other
                                affiliations include Bharathiyar Arts & Science College for Women and Bharathiyar Girls
                                HSS
                                College.</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Education</h3>
                    <ul>
                        <li><a><i class='bx bx-book'></i>Bharathiyar Arts & Science College for Women</a></li>
                        <li><a><i class='bx bx-book-alt'></i>Bharathiyar Institute of Engineering for Women </a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
