import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ExportServicesComponent } from './components/pages/export-services/export-services.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { OilGasComponent } from './components/pages/oil-gas/oil-gas.component';
import { InfotechComponent } from './components/pages/infotech/infotech.component';
import { RetailSalesComponent } from './components/pages/retail-sales/retail-sales.component';
import { CSRComponent } from './components/pages/csr/csr.component';
import { HospitalityComponent } from './components/pages/hospitality/hospitality.component';
import { FinanceComponent } from './components/pages/finance/finance.component';
import { RealestateComponent } from './components/pages/realestate/realestate.component';
import { ManufacturingComponent } from './components/pages/manufacturing/manufacturing.component';
import { InfraComponent } from './components/pages/infra/infra.component';
import { GoldoneComponent } from './components/pages/goldone/goldone.component';
import { FpoComponent } from './components/pages/fpo/fpo.component';
import { CovidCSRComponent } from './components/pages/covid-csr/covid-csr.component';
import { PoolambadiCsrComponent } from './components/pages/poolambadi-csr/poolambadi-csr.component';


const routes: Routes = [
    { path: '', redirectTo: '', pathMatch: 'full' },
    { path: '', component: HomeOneComponent },
    { path: 'home', component: HomeOneComponent },
    { path: 'about', component: AboutComponent },
    { path: 'team', component: TeamComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'services-details', component: ServicesDetailsComponent },
    { path: 'export-services', component: ExportServicesComponent },
    { path: 'oil-gas', component: OilGasComponent },
    { path: 'infotech', component: InfotechComponent },
    { path: 'retail-sales', component: RetailSalesComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'careers', component: CareersComponent },
    { path: 'csr', component: CSRComponent },
    { path: 'hospitality', component: HospitalityComponent },
    { path: 'finance', component: FinanceComponent },
    { path: 'realestate', component: RealestateComponent },
    { path: 'manufacturing', component: ManufacturingComponent },
    { path: 'infra', component: InfraComponent },
    { path: 'goldone', component: GoldoneComponent },
    { path: 'fpo', component: FpoComponent },
    { path: 'covidcsr', component: CovidCSRComponent },
    { path: 'poolambadicsr', component: PoolambadiCsrComponent },


    { path: '**', component: ErrorComponent }, // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
