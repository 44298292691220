import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-hospitality',
    templateUrl: './hospitality.component.html',
    styleUrls: ['./hospitality.component.scss']
})
export class HospitalityComponent implements OnInit {

    constructor(private backend: BackendApiService) { }

    ngOnInit(): void {
        this.backend.createVisitorLogs('Hospitality-page');
    }

}
