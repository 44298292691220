<head>
    <link rel="canonical" href="https://plusmax.com.my/#/goldone" />
</head>
<div class="page-banner bg-16 mt-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 class="text-white">Gold One</h2>
                    <ul>
                        <li><a class="text-white" routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12"
                style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: medium;">
                <div class="service-details-text">
                    <h3>Gold One</h3>
                    <div class="fon">
                        <div class="service-image">
                            <img src="assets/img/goldone/03.jpg" alt="PLUSMAX Infrastructure">
                        </div>
                        <p><i class='bx bx-coin'
                                style="color:rgb(239, 222, 34); font-size: 1.2em;"></i>&nbsp;<strong>Plus Max ventured
                                into Gold Refinery by incorporating Gold One Refinery Private Limited in 2019 with the
                                goal of
                                providing pure gold bars to businesses and investors. We have a wide variety of sizes
                                and weights to choose from, so you
                                can find the perfect bar for your needs. Our goal is to provide our customers with the
                                best benefits and the highest
                                quality gold possible. We always price our products at the current market rate and
                                follow all industry standards when
                                refining our gold.</strong></p>
                        <div class="row">
                            <div class="image">
                                <img src="assets/img/goldone/bar-type1.png" alt="Gold One">

                                <img src="assets/img/goldone/bar-type2.png" alt="Gold One">

                                <img src="assets/img/goldone/bar-type3.png" alt="Gold One">
                            </div>
                        </div>
                        <div class="row">
                            <div class="image">
                                <img src="assets/img/goldone/bar-type4.png" alt="Gold One">

                                <img src="assets/img/goldone/bar-type6.png" alt="Gold One">

                                <img src="assets/img/goldone/bar-type5.png" alt="Gold One">
                            </div>
                        </div>
                        <p><i class='bx bx-coin'
                                style="color:rgb(239, 222, 34); font-size: 1.2em;"></i>&nbsp;<strong>Gold One Refinery
                                Private Limited has earned a formal acknowledgement of laboratory proficiency from the
                                NABL in
                                accordance with international standards. This provides our customers and investors with
                                the confidence that they're
                                getting a top-quality product, increasing business on account of improved customer
                                satisfaction. It also helps in saving
                                both time and money as the re-testing of products is no longer necessary.</strong></p>
                        <p><i class='bx bx-coin'
                                style="color:rgb(239, 222, 34); font-size: 1.2em;"></i>&nbsp;<strong>After accreditation
                                by authorized laboratories, Gold One stands as the best seller in both domestic and
                                international
                                markets.</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Gold One</h3>
                    <ul>
                        <li><a><i class='bx bx-coin'></i>Gold One</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>