import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-services-details',
    templateUrl: './services-details.component.html',
    styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

    constructor(private backend: BackendApiService) { }

    ngOnInit(): void {
        this.backend.createVisitorLogs('Education-page');
    }

}
