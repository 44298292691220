import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IPInformation, VisitorLog } from './backend.model';
import { Observable } from 'rxjs';
import { ServiceResult } from './common';
@Injectable({
    providedIn: 'root',
})
export class BackendApiService {
    url = environment.apiUrl;
    deviceInfo = null;
    constructor(
        private http: HttpClient,
        private deviceService: DeviceDetectorService
    ) { }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }),
        responseType: 'text' as 'json'
    };
    createCareer(Careers: any): Observable<any> {
        return this.http.post<any>(
            this.url + '/api/Careers/create-career',
            Careers,
            this.httpOptions
        );
    }
    createContact(Contact: any): Observable<any> {
        return this.http.post<any>(
            this.url + '/api/Contact/create-contact',
            Contact,
            this.httpOptions
        );
    }
    createVisitorLogs(page: any) {
        this.http
            .get('https://ipinfo.io/?token=a3288d64cd201e')
            .subscribe((x: IPInformation) => {
                this.deviceInfo = this.deviceService.getDeviceInfo();
                const isMobile = this.deviceService.isMobile();
                const isTablet = this.deviceService.isTablet();
                const isDesktopDevice = this.deviceService.isDesktop();
                let visitor = new VisitorLog();
                visitor.brand = '';
                visitor.operatingSystem = this.deviceInfo.os;
                visitor.browser = this.deviceInfo.browser;
                visitor.userAgent = this.deviceInfo.userAgent;
                visitor.osVersion = this.deviceInfo.os_version;
                visitor.page = page;
                visitor.ipAddress = x.ip;
                visitor.country = x.country;
                visitor.city = x.city + ',' + x.region;
                visitor.coordinates = x.loc;
                visitor.zipCode = x.postal;
                visitor.timeZone = new Date().toTimeString();
                visitor.visitedDateTime = new Date().toISOString();
                visitor.year = new Date().getFullYear();
                visitor.deviceType =
                    isMobile == true
                        ? 'mobile'
                        : isTablet
                            ? 'tablet'
                            : isDesktopDevice
                                ? 'desktop'
                                : 'unknown';
                // console.log(`${this.url}/api/visitor/create-visitor`);
                this.http
                    .post(
                        `${this.url}/api/visitor/create-visitor`,
                        visitor,
                        this.httpOptions,
                    )
                    .subscribe();
            });
    }
}
