<app-preloader *ngIf="!isShowLanding"></app-preloader>
<app-navbar *ngIf="!(location == '/coming-soon') && !isShowLanding"></app-navbar>
<app-page-landing *ngIf="isShowLanding"></app-page-landing>
<router-outlet *ngIf="!isShowLanding"></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon') && !isShowLanding"></app-footer>

<!-- <app-preloader></app-preloader>
<app-page-landing></app-page-landing>
<app-navbar *ngIf="!(location == '/coming-soon')"></app-navbar>
<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon')"></app-footer> -->
