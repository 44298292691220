import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poolambadi-csr',
  templateUrl: './poolambadi-csr.component.html',
  styleUrls: ['./poolambadi-csr.component.scss']
})
export class PoolambadiCsrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
