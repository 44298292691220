<head>
    <link rel="canonical" href="https://plusmax.com.my/#/oil-gas" />
</head>
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Oil & Gas</h2>
                    <ul>
                        <li><a routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100 fon">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="service-image">
                        <img src="assets/img/Oil-Gas/g1.jpg" alt="Plus Max Oil and Gas">
                    </div>
                    <h3>Oil & Gas</h3>
                    <p class="fon"><i class='bx bxs-gas-pump'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>
                            Plus Max entered the infrastructure and construction markets two decades ago, with the
                            formation of Growth Avenue Sdn
                            Bhd (GASB). We have managed to broaden our prospects in the oil and gas industry, where we
                            provide a myriad of services
                            to diverse industry players.
                        </strong></p>
                    <div class="image">
                        <img src="assets/img/Oil-Gas/g2.jpg" alt="Plus Max Oil and Gas">
                    </div>
                    <p class="fon"><i class='bx bxs-gas-pump'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Our services entail
                            pipeline inspection services, topside major maintenance, construction and commissioning,
                            oilfield
                            optimization services, designing and curating process equipment to offshore marine support,
                            to list a few. All our
                            services are diligently monitored and customized according to the requisites of the client.
                            We ensure that all business
                            operations adhere to ISO 9001:2015 quality and compliance standards, thereby increasing
                            efficiency and security.</strong>
                    </p>
                    <p class="fon"><i class='bx bxs-plane-take-off'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong> With a dedicated team
                            with strong values and expertise in the industry, GASB has recently been awarded Gold in its
                            category for Best Innovation by the STAR Outstanding Business Awards 2021 (Malaysia). We are
                            constantly evolving and
                            expanding our business, harnessing advanced technologies to meet specific objectives while
                            prudently aiming to address
                            market linkages.</strong></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Oil & Gas</h3>
                    <ul>
                        <li><a><i class='bx bxs-ship'></i> Ocean Freight</a></li>
                        <li><a><i class='bx bx-transfer'></i> B2B Exchange</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
